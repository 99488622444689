import axiosInstance from "../axiosInstance";

export interface Collection {
  name: string;
}

interface CollectionResponse {
  collection: { collection: string };
  meta: { system: boolean; hidden: boolean };
}

export const getCollections = async (): Promise<Collection[]> => {
  const response = await axiosInstance.get(
    "http://vps-a2b86adc.vps.ovh.net:8055/collections"
  );
  const collections = response.data.data
    .filter(
      (collection: CollectionResponse) =>
        !collection.meta.system && !collection.meta.hidden
    )
    .map((collection: CollectionResponse) => ({ name: collection.collection }));
  return collections;
};
