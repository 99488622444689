import { FC, Key } from "react";

import { Flex } from "antd";

import DuplicateTransactions from "./DuplicateTransactions";
import RefreshAddress from "./RefreshAddress";

const TransactionFaoActions: FC<{ selectedRowKeys: Key[] }> = ({
  selectedRowKeys,
}) => {
  const ids = selectedRowKeys.map((k) => k.toString());

  return (
    <Flex gap={16}>
      <DuplicateTransactions ids={ids} />
      <RefreshAddress ids={ids} />
    </Flex>
  );
};
export default TransactionFaoActions;
