import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import { ConfigProvider } from "antd";
import { groupBy } from "lodash";
import { RouterProvider, createBrowserRouter, defer } from "react-router-dom";

import AppLayout from "./AppLayout";
import axiosInstance from "./axiosInstance";
import Collection from "./Collection";
import Collections from "./Collections";
import Error from "./Error";
import { transformToApiQueryParams } from "./hooks/useQueryParamsToState";
import Login from "./Login";
import reportWebVitals from "./reportWebVitals";
import { getCollectionFields } from "./services/collectionFields";
import { getCollectionItems } from "./services/collectionItems";
import { getCollections } from "./services/collections";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    loader: async () => {
      const response = await axiosInstance.get("/users/me");
      return {
        collections: await getCollections(),
        user: response.data.data,
      };
    },
    children: [
      {
        path: "collections",
        element: <Collections />,
        loader: async () => ({ collections: await getCollections() }),
      },
      {
        path: "collections/:collectionId",
        element: <Collection />,
        loader: async ({ params, request }) => {
          const collectionId = params.collectionId!;
          const fields = await getCollectionFields(collectionId);

          const apiParams = transformToApiQueryParams(
            fields,
            new URL(request.url).searchParams
          );
          const data = getCollectionItems(
            collectionId,
            fields,
            apiParams.toString()
          );

          return defer({
            collectionId,
            fields,
            groupedFields: groupBy(fields, "group"),
            data,
            limit: +apiParams.get("limit")!,
            page: +apiParams.get("page")!,
          });
        },
      },
    ],
    errorElement: (
      <Error
        title="Quelque chose s'est mal passé!"
        subtitle="Veuillez réessayer svp, et si ca persiste, contacter l'administrateur."
        action={{
          on: () => (window.location.href = "/login"),
          title: "Se connecter",
        }}
      />
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: (
      <Error
        status="404"
        title="La page demandée n'existe pas."
        action={{
          on: () => (window.location.href = "/"),
          title: "Vers la page d'acceuil",
        }}
      />
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#001482",
          colorBgBase: "#ffffff",
          colorBgContainer: "#ffffff",
          colorLink: "#001482",
          colorLinkActive: "#001482",
          fontFamily: '"Questrial", sans-serif',
          fontFamilyCode: '"Montserrat", sans-serif',
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
