import axios from "axios";

import { getToken } from "./tokens";

const axiosInstance = axios.create({
  baseURL: "http://vps-a2b86adc.vps.ovh.net:8055/",
});

axiosInstance.interceptors.request.use((config) => {
  const { token } = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers["Content-Type"] = "application/json";

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    if (
      error.response &&
      error.response.status === 401 &&
      !error.request.responseURL.includes("auth/login")
    ) {
      localStorage.removeItem("x-token");
      window.location.href = "/login";
      return;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
