import { FC } from "react";

import { Button, Result } from "antd";
import { ResultStatusType } from "antd/es/result";

interface ErrorProps {
  status?: ResultStatusType;
  title: string;
  subtitle?: string;
  action: {
    on: () => void;
    title: string;
  };
}

const Error: FC<ErrorProps> = ({
  status = "error",
  title,
  subtitle,
  action,
}) => {
  return (
    <Result
      status={status}
      title={title}
      subTitle={subtitle}
      extra={[
        <Button onClick={action.on} type="primary" key="login">
          {action.title}
        </Button>,
      ]}
    />
  );
};

export default Error;
