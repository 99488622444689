import { FC, useEffect, useState } from "react";

import { Button, DatePicker, Divider, Flex } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import { FilterDropdownProps } from "antd/es/table/interface";
import dayjs from "dayjs";

const DateFilter: FC<{
  dropdownProps: FilterDropdownProps;
}> = ({ dropdownProps }) => {
  const [selectedDateRange, setSelectedDateRange] =
    useState<RangePickerProps["value"]>();

  useEffect(() => {
    if (dropdownProps.selectedKeys.length > 0) {
      setSelectedDateRange([
        dayjs(dropdownProps.selectedKeys[0] as string, "DD/MM/YYYY"),
        dayjs(dropdownProps.selectedKeys[1] as string, "DD/MM/YYYY"),
      ]);
    } else {
      setSelectedDateRange([null, null]);
    }
  }, [dropdownProps.selectedKeys]);

  return (
    <Flex vertical gap={8} style={{ padding: 8 }}>
      <DatePicker.RangePicker
        allowEmpty={[false, false]}
        value={selectedDateRange}
        allowClear
        onChange={(_, dateStrings) =>
          dropdownProps.setSelectedKeys(dateStrings)
        }
        format="DD/MM/YYYY"
      />
      <Divider style={{ marginTop: 0, marginBottom: 0 }} />
      <Flex justify="space-between">
        <Button
          onClick={() => dropdownProps.setSelectedKeys([])}
          type="link"
          size="small"
          disabled={dropdownProps.selectedKeys.length === 0}
        >
          Réinitialiser
        </Button>
        <Button
          onClick={() => dropdownProps.confirm()}
          type="primary"
          size="small"
        >
          OK
        </Button>
      </Flex>
    </Flex>
  );
};

export default DateFilter;
