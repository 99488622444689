import { DatabaseOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Flex, Layout, Typography } from "antd";
import {
  Link,
  Outlet,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";

import { Collection } from "./services/collections";

const { Header, Content } = Layout;

const AppLayout = () => {
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const { collections, user } = useLoaderData() as {
    collections: Collection[];
    user: any;
  };

  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#f5f5f5",
          alignItems: "center",
        }}
      >
        <Flex gap={24} align="center">
          <img height={54} src="/logo.svg" alt="Melcarne" />
          <span>
            <Link to="/">
              <Typography.Text
                strong
                style={{
                  fontSize: 18,
                  textTransform: "uppercase",
                  letterSpacing: 2.5,
                }}
              >
                Directus Frontend
              </Typography.Text>
            </Link>
            {collectionId && (
              <Typography.Text
                strong
                style={{
                  fontSize: 16,
                  textTransform: "uppercase",
                  letterSpacing: 2.5,
                }}
              >
                &nbsp;&nbsp;/&nbsp;&nbsp;{collectionId}
              </Typography.Text>
            )}
          </span>
        </Flex>

        <Flex gap={16} align="center">
          <Dropdown
            trigger={["click"]}
            menu={{
              items: collections.map(({ name }: Collection) => ({
                key: name,
                label: (
                  <Link key={name} to={`collections/${name}`}>
                    {name}
                  </Link>
                ),
              })),
            }}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <Button icon={<DatabaseOutlined />}>Collections</Button>
          </Dropdown>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                { key: 1, label: `${user.first_name} ${user.last_name}` },
                {
                  key: 2,
                  label: (
                    <Button
                      type="link"
                      size="small"
                      onClick={() => {
                        localStorage.removeItem("x-token");
                        navigate("/login");
                      }}
                    >
                      Se déconnecter
                    </Button>
                  ),
                },
              ],
            }}
            placement="bottomRight"
          >
            <Avatar
              style={{ cursor: "pointer" }}
              size="large"
              icon={<UserOutlined />}
            />
          </Dropdown>
        </Flex>
      </Header>
      <Content style={{ height: "calc(100vh - 64px)" }}>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default AppLayout;
