import { FC, Fragment, useCallback, useEffect, useState } from "react";

import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import fr from "antd/es/date-picker/locale/fr_FR";
import { isEmpty, omit, pickBy } from "lodash";
import { useLoaderData } from "react-router-dom";
import { Link } from "react-router-dom";

import DuplicateTransactions from "./actions/DuplicateTransactions";
import LinkToFao from "./actions/LinkToFao";
import RefreshAddress from "./actions/RefreshAddress";
import ValidateUpdates from "./actions/ValidateUpdates";
import { Field, FieldOption, GroupedFields } from "./services/collectionFields";
import { ItemChanges, saveItemChanges } from "./services/collectionItems";

const { TextArea } = Input;

interface EditCollectionProps {
  editItem: any;
  onCloseEdit: () => void;
  onEditNext?: () => void;
  onEditPrevious?: () => void;
}

const EditCollection: FC<EditCollectionProps> = ({
  editItem,
  onCloseEdit,
  onEditNext,
  onEditPrevious,
}) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [changedValues, setChangedValues] = useState<ItemChanges>({});
  const { collectionId, groupedFields } = useLoaderData() as {
    collectionId: string;
    groupedFields: GroupedFields;
  };

  useEffect(() => {
    form.setFieldsValue(editItem);
  }, [editItem, form]);

  const handleValuesChange = useCallback(
    (updatedValues: any) => {
      const actuallyChangedValues = pickBy(updatedValues, (_val, key) => {
        return editItem[key] !== updatedValues[key];
      });

      if (!isEmpty(actuallyChangedValues)) {
        setChangedValues((values) => ({ ...values, ...actuallyChangedValues }));
      } else {
        setChangedValues((values) =>
          omit({ ...values }, Object.keys(updatedValues)[0])
        );
      }
    },
    [editItem, setChangedValues]
  );

  const handleClose = () => {
    setChangedValues({});
    onCloseEdit();
  };

  const handleSaveItemChanges = async () => {
    if (!isEmpty(changedValues)) {
      try {
        await saveItemChanges(editItem.id, collectionId, changedValues);
        messageApi.open({
          type: "success",
          content: "La mise à jour a été effectuée avec succès.",
        });
      } catch (error) {
        console.error(error);
        messageApi.open({
          type: "error",
          content: "Une erreur est survenue lors de la mise à jour.",
        });
      }

      handleClose();
    }
  };

  return (
    <>
      {contextHolder}
      <Drawer
        width="85%"
        title={
          <Flex
            style={{ paddingLeft: 12 }}
            justify="space-between"
            align="center"
          >
            {editItem && (
              <Typography.Text
                strong
                style={{ fontSize: 16 }}
              >{`${editItem.NoParcelle} - ${editItem.Requete} - ${editItem.Adresse}, ${editItem.NPA} ${editItem.CommuneAdresse}`}</Typography.Text>
            )}
            <Flex gap={16}>
              <Button
                icon={<LeftOutlined />}
                iconPosition="start"
                disabled={!onEditPrevious}
                onClick={() => {
                  setChangedValues({});
                  onEditPrevious && onEditPrevious();
                }}
              >
                Previous
              </Button>
              <Button
                icon={<RightOutlined />}
                iconPosition="end"
                disabled={!onEditNext}
                onClick={() => {
                  setChangedValues({});
                  onEditNext && onEditNext();
                }}
              >
                Next
              </Button>
            </Flex>
          </Flex>
        }
        open={!!editItem && !isEmpty(editItem)}
        onClose={handleClose}
        footer={
          <Flex justify="space-between">
            {editItem && (
              <Flex gap="middle" align="center">
                {collectionId === "transactions_updates" && (
                  <ValidateUpdates ids={[editItem.id]} />
                )}
                <RefreshAddress ids={[editItem.id]} />
                <DuplicateTransactions ids={[editItem.id]} />
                {collectionId === "transactions_updates" && (
                  <LinkToFao
                    requete={editItem.Requete.split("/").slice(0, 2).join("/")}
                  />
                )}
              </Flex>
            )}
            <Flex gap="middle">
              <Button onClick={handleClose}>Annuler</Button>
              <Button
                onClick={handleSaveItemChanges}
                type="primary"
                disabled={isEmpty(changedValues)}
              >
                Sauvegarder
              </Button>
            </Flex>
          </Flex>
        }
      >
        {editItem && (
          <Form
            size="middle"
            form={form}
            layout="vertical"
            autoComplete="off"
            initialValues={editItem}
            onValuesChange={handleValuesChange}
          >
            {Object.keys(groupedFields).map((group: string) => {
              return (
                <Fragment key={group}>
                  {group && group !== "null" && (
                    <Divider orientation="left">
                      <Typography.Title level={4}>
                        {group.toUpperCase()}
                      </Typography.Title>
                    </Divider>
                  )}

                  <Row gutter={24}>
                    {groupedFields[group]
                      .sort((f1, f2) => f1.sort - f2.sort)
                      .map((field: Field) => {
                        const { name, label } = field;
                        return (
                          <Col key={name} sm={12} md={8} lg={8} xl={6}>
                            <Form.Item
                              key={name}
                              name={name}
                              label={label}
                              style={{ marginBottom: 16 }}
                              validateStatus={
                                changedValues[name] ? "warning" : ""
                              }
                            >
                              {getEditComponent(field, editItem)}
                            </Form.Item>
                          </Col>
                        );
                      })}
                  </Row>
                </Fragment>
              );
            })}
          </Form>
        )}
      </Drawer>
    </>
  );
};

export default EditCollection;

const getEditComponent = (field: Field, editItem: any) => {
  const { ui, type, name, options } = field;
  switch (ui) {
    case "datetime":
      return <DatePicker style={{ width: "100%" }} locale={fr} />;

    case "input-multiline":
      return <TextArea rows={3} />;

    case "select-dropdown":
      return (
        <Select style={{ width: "100%" }}>
          {(options || []).map((option: FieldOption) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      );

    case "input":
      if (type === "integer") {
        if (name.indexOf("Prix") > -1) {
          return (
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) =>
                value?.replace(/CHF\s?|(,*)/g, "") as unknown as number
              }
              style={{ width: "100%" }}
            />
          );
        }

        return <InputNumber style={{ width: "100%" }} />;
      }

      return <Input />;

    case "map":
      if (editItem[name]) {
        const { coordinates } = editItem[name];
        const lat = coordinates[1];
        const long = coordinates[0];
        return (
          <Link
            target="_blank"
            to={`https://www.google.ch/maps/place/${lat},${long}`}
          >
            <Typography.Link>{`${lat} / ${long}`}</Typography.Link>
          </Link>
        );
      }
      return <div></div>;

    default:
      return <Input />;
  }
};
