import { FC, useState } from "react";

import { Button, Modal, message } from "antd";

import { duplicateItems } from "../services/collectionItems";

const DuplicateTransactions: FC<{ ids: string[] }> = ({ ids }) => {
  const [openDuplication, setOpenDuplication] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      {contextHolder}
      <Button
        style={{ backgroundColor: "#42a5f5" }}
        onClick={() => setOpenDuplication(!openDuplication)}
        disabled={ids.length === 0}
        loading={loading}
      >
        {`Dupliquer les éléments${ids.length > 0 ? ` (${ids.length})` : ""}`}
      </Button>

      <Modal
        open={openDuplication}
        closable={false}
        onCancel={() => setOpenDuplication(!openDuplication)}
        title="Dupliquer les éléments selectionnés?"
        width={400}
        okText="Dupliquer"
        cancelText="Annuler"
        loading={loading}
        onOk={async () => {
          setLoading(true);
          try {
            await duplicateItems(ids);

            messageApi.open({
              type: "success",
              content: "Les éléments ont été dupliqués avec succès.",
            });
          } catch (error) {
            console.log(error);
            messageApi.open({
              type: "error",
              content: "Une erreur est survenue lors de l'opération.",
            });
          } finally {
            setLoading(false);
          }
        }}
      ></Modal>
    </>
  );
};

export default DuplicateTransactions;
