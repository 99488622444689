import { FC, useState } from "react";

import { Button, message } from "antd";

import { refreshAdresses } from "../services/collectionItems";

const RefreshAddress: FC<{ ids: string[] }> = ({ ids }) => {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      {contextHolder}
      <Button
        style={{ backgroundColor: "#4caf50" }}
        onClick={async () => {
          setLoading(true);
          try {
            await refreshAdresses(ids);

            messageApi.open({
              type: "success",
              content: "Les adresses ont été mises à jour avec succès.",
            });
          } catch (error) {
            console.log(error);
            messageApi.open({
              type: "error",
              content: "Une erreur est survenue lors de la mise à jour.",
            });
          } finally {
            setLoading(false);
          }
        }}
        disabled={ids.length === 0}
        loading={loading}
      >
        {`Mettre à jour les adresses${
          ids.length > 0 ? ` (${ids.length})` : ""
        }`}
      </Button>
    </>
  );
};

export default RefreshAddress;
