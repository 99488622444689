import ReactDragListView from "react-drag-listview";

function ReactDragListViewColumn({ onFieldMove, children }) {
  return (
    <ReactDragListView.DragColumn
      onDragEnd={(fromIndex, toIndex) => {
        onFieldMove(fromIndex, toIndex);
      }}
      nodeSelector="th"
      handleSelector=".dragHandler"
      ignoreSelector="react-resizable-handle"
    >
      {children}
    </ReactDragListView.DragColumn>
  );
}

export default ReactDragListViewColumn;
