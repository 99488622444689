import dayjs from "dayjs";

import { Field } from "./collectionFields";

import axiosInstance from "../axiosInstance";

export type Item = Record<string, any>;

export interface CollectionPayload {
  data: {
    items: Item[];
    nbItems: number;
    total: number;
  };
  fields: Field[];
  limit: number;
  page: number;
  loading: boolean;
  collectionId: string;
}

export const getCollectionItems = async (
  collectionId: string,
  fields: Field[],
  searchParams: string
) => {
  const response = await axiosInstance.get(
    `http://vps-a2b86adc.vps.ovh.net:8055/items/${collectionId}?meta=*&fields[]=id,${fields
      .map((field) => field.name)
      .join(",")}&${searchParams}`
  );
  const data = response.data.data;

  const transformedRow = data.map((row: Item) => {
    let newRow = { ...row, key: row.id };
    fields.forEach((field) => {
      if (field.type === "date" && row[field.name]) {
        newRow = {
          ...newRow,
          [field.name]: dayjs(
            new Intl.DateTimeFormat("ch").format(new Date(row[field.name])),
            "DD/MM/YYYY"
          ),
        };
      } else {
        newRow = { ...newRow, [field.name]: row[field.name] };
      }
    });

    return newRow;
  });

  return {
    items: transformedRow,
    nbItems: response.data.meta.filter_count,
    total: response.data.meta.total_count,
  };
};

export type ItemChanges = Record<string, any>;

export const saveItemChanges = async (
  id: number,
  collectionId: string,
  changes: ItemChanges
) => {
  await axiosInstance.patch(
    `http://vps-a2b86adc.vps.ovh.net:8055/items/${collectionId}/${id}`,
    changes
  );
};

export const duplicateItems = async (ids: string[]) => {
  await Promise.all(
    ids.map((id) =>
      axiosInstance.post(
        `http://vps-a2b86adc.vps.ovh.net:5000/duplicate_transaction`,
        { body: { key: id } }
      )
    )
  );
};

export const refreshAdresses = async (ids: string[]) => {
  await Promise.all(
    ids.map((id) =>
      axiosInstance.post(
        `http://vps-a2b86adc.vps.ovh.net:5000/refresh_address`,
        { body: { key: id } }
      )
    )
  );
};
