export interface TokenProps {
  isAuthenticated?: boolean;
  token?: string;
  refreshToken?: string;
  expires?: number;
}

export const getToken = (): TokenProps => {
  const token = localStorage.getItem("x-token");

  let authenticationProps: TokenProps = {};
  if (token) {
    const jsonToken = JSON.parse(token);
    authenticationProps = {
      ...authenticationProps,
      isAuthenticated: true,
      token: jsonToken.access_token,
      refreshToken: jsonToken.refresh_token,
      expires: jsonToken.expires,
    };
  }

  return authenticationProps;
};
