import { FC } from "react";

import { Button } from "antd";

const LinkToFao: FC<{ requete: string }> = ({ requete }) => {
  return (
    <Button
      type="link"
      onClick={() =>
        window.open(
          `/collections/transactions_fao?filter=Requete%3A%3A%3A${requete}***`,
          "_blank"
        )
      }
    >
      Transaction originale
    </Button>
  );
};

export default LinkToFao;
